import styled from "@emotion/styled"
import { navigate } from "gatsby"
import React, { useContext, useEffect, useRef, useState } from "react"
import { Box, Flex, Image, Text } from "theme-ui"
import CrossIcon from "../../assets/icons/cross-cart.svg"
import Decrement from "../../assets/images/decrement.png"
import Increment from "../../assets/images/increment.png"
import { InterfaceContext } from "../../context/interface-context"
import StoreContext from "../../context/store-context"
import {
  trackAddToCart,
  trackCheckoutInitialized,
  trackRemoveFromCart
} from "../../services/analytics"
import Medusa from "../../services/api"
import {
  formatCartShippingTotal,
  formatCartTotal,
  formatMoneyAmount,
  formatPrices
} from "../../util/prices"
import Button from "../base/buttons"
import Input from "../base/input-field"
import { MedusaCheckoutContext } from "../medusa-checkout-builder"
import VariantPrice from "../product/price"
import CartUpsell from "./cart-upsell"

const CartContainer = styled(Flex)`
  background-color: ${(props) => props.theme.colors.background};
  color: ${(props) => props.theme.colors.darkGreen};
  flex-direction: column;
  height: 100%;
  border-left: ${(props) => props.theme.borders.darkGreen};
  z-index: 2002;
  align-self: flex-end;
  position: fixed;

  transform: translateX(100%);
  -webkit-transform: translateX(100%);

  transition: transform 0.5s ease-out;
  ${(props) =>
    props.show &&
    `
  transform: translateX(0);
  `}
`

const CheckoutCartContainer = styled(Flex)`
  background-color: ${(props) => props.theme.colors.background};
  color: ${(props) => props.theme.colors.darkGreen};
  flex-direction: column;
  max-height: 100vh;
  border-left: ${(props) => props.theme.borders.darkGreen};
`

const Wrapper = styled(Flex)`
  flex-direction: column;
  height: 100%;
  box-shadow: ${({ theme }) => theme.shadows.cartShadow};
`

const DiscountInput = styled(Input)`
  width: 100%;
  height: 50px;
  padding-left: 15px !important;
  padding-right: 15px !important;
  padding-bottom: 4px !important; // due to misaligned font
  margin-bottom: 15px !important;
`

const ApplyPromoButton = styled(Text)`
  display: flex;
  padding-top: 0px;
  justify-content: center;
  align-items: center;
  width: 30%;
  cursor: pointer;
  height: 50px;
  background-color: ${(props) => props.theme.colors.darkGreen};
  color: ${(props) => props.theme.colors.background};
  border: ${(props) => props.theme.borders.thin};
`

const FreeShippingText = styled(Text)`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 15px;
`

const CheckoutButton = styled(Button)`
  height: 50px;
  border-radius 0;
  padding-bottom: 2px;
  margin-bottom: 15px;
`

const CartHeader = styled(Flex)`
  border-bottom: 1px solid #245437;
  align-items: center;
  margin-top: 4px;
  padding-right: 30px;
  padding-left: 30px;
  padding-top: 22px;
  padding-bottom: 22px;
  width: 100%;
  justify-content: space-between;
`

export const CartItem = ({ idx, item, cart, handleUpdateItem, product }) => {
  const name = item.title.split(" - ")
  const color = name.pop()

  const size = item.metadata?.size || ""

  let itemStyle = { py: 1 }

  if (cart?.items.length - 1 > idx) {
    itemStyle = { ...itemStyle, borderBottom: "1px solid #24543729" }
  }

  return (
    <Flex sx={itemStyle}>
      <Image src={item.thumbnail} sx={{ maxWidth: "20%", p: 1 }} />
      <Flex
        sx={{
          flexDirection: "column",
          width: "100%",
          mx: ["26px"],
          mt: ["19px"],
          mb: ["15px"],
          fontSize: ["12px", "14px"],
        }}
      >
        {color && name && (
          <Flex sx={{ flexDirection: "column" }}>
            <Text>{name?.length ? name.join(" - ") : product?.title}</Text>
            <Text>
              {product?.handle !== "gift-card"
                ? `${color}${size && ` - ${size}`}`
                : ""}
            </Text>
          </Flex>
        )}
        <Box sx={{ mt: "10px" }} />
        <Flex sx={{ justifyContent: "space-between", alignItems: "center" }}>
          <Flex sx={{ alignItems: "center" }}>
            <Image
              src={Decrement}
              sx={{ height: "25px", width: "25px", cursor: "pointer" }}
              onClick={() => {
                if (handleUpdateItem) {
                  handleUpdateItem({
                    item,
                    quantity: item.quantity - 1,
                    product,
                    type: "decr",
                  })
                }
              }}
            />
            <Text sx={{ mx: "16px" }}>{item.quantity}</Text>
            <Image
              src={Increment}
              sx={{ height: "25px", width: "25px", cursor: "pointer" }}
              onClick={() =>
                handleUpdateItem({
                  item,
                  quantity: item.quantity + 1,
                  product,
                })
              }
            />
          </Flex>
          <Flex>
            {!product?.is_giftcard ? (
              <VariantPrice cart={cart} variant={product?.variants[0]} />
            ) : (
              <Text>{formatPrices(cart, item.unit_price * item.quantity)}</Text>
            )}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

const CartDrawer = ({ isCheckout = false }) => {
  const { isCartOpen, toggleCart } = useContext(InterfaceContext)
  const {
    cart,
    updateLineItem,
    addDiscount,
    freeShippingLimit,
    removeDiscount,
    addGiftCard,
    removeGiftCard,
  } = useContext(StoreContext)

  const [products, setProducts] = useState([])

  const { cart: checkoutCart, setCartFromObject } = useContext(
    MedusaCheckoutContext
  )

  const [promo, setPromo] = useState("")
  const [giftCard, setGiftCard] = useState("")

  const cartRef = useRef()

  useEffect(() => {
    const fetchProducts = async () => {
      const prods = cart.items.map((item) => item.variant.product_id)

      let query = ``

      prods.forEach((id) => (query += `id=${id}&`))

      if (cart.id) {
        query += `&cart_id=${cart.id}&limit=15`
      }
      const prod = await Medusa.products.list(query)

      setProducts(prod?.data?.products)
    }

    if (isCartOpen || isCheckout) {
      fetchProducts()
    }
  }, [cart.items, cart.id, isCartOpen, isCheckout])

  const escFunction = (event) => {
    if (event.key === "Escape") {
      toggleCart()
    }
  }

  useEffect(() => {
    if (isCartOpen) {
      document.addEventListener("keydown", escFunction, true)
    }

    return () => {
      document.removeEventListener("keydown", escFunction, true)
    }
  }, [isCartOpen])

  const handleClick = (e) => {
    if (cartRef.current && !cartRef.current.contains(e.target)) {
      // inside click
      toggleCart()
    }
  }

  useEffect(() => {
    // add when mounted
    if (isCartOpen) {
      document.addEventListener("mousedown", handleClick, true)
    }
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClick, true)
    }
  }, [isCartOpen])

  useEffect(() => {
    if (cart && setCartFromObject) {
      setCartFromObject(cart)
    }
  }, [cart])

  const handleUpdateItem = ({ item, quantity, product, type = "incr" }) => {
    updateLineItem({
      lineId: item.id,
      quantity,
    })

    if (type === "incr") {
      trackAddToCart(cart, product, item.variant, 1, item.unit_price)
    } else {
      trackRemoveFromCart(cart, product, item.variant, 1, item.unit_price)
    }
  }

  const handleToCheckout = () => {
    trackCheckoutInitialized(cart)
    toggleCart()
    navigate("/checkout")
  }

  let cartStyle = {}
  let CartWrapper = null

  if (isCheckout) {
    CartWrapper = CheckoutCartContainer
    cartStyle = { width: ["100%", "40%", "40%"] }
  } else {
    CartWrapper = CartContainer
    cartStyle = { width: ["100%", "550px"] }
  }

  return (
    <CartWrapper ref={cartRef} show={isCartOpen} sx={{ ...cartStyle }}>
      <Wrapper>
        {!isCheckout && (
          <CartHeader>
            <Text sx={{ fontSize: ["12px", "14px"] }}>Cart</Text>
            <Flex sx={{ height: "20px", cursor: "pointer" }}>
              <CrossIcon className="close-icon" onClick={() => toggleCart()} />
            </Flex>
          </CartHeader>
        )}
        {!cart.items?.length ? (
          <Flex
            sx={{
              height: "100%",
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            Empty Cart
          </Flex>
        ) : (
          <Flex
            sx={{
              flexDirection: "column",
              overflowY: "scroll",
              maxHeight: "100%",
            }}
          >
            <Flex
              sx={{
                flexDirection: "column",
              }}
            >
              {cart.items.map((item, i) => (
                <CartItem
                  key={i}
                  idx={i}
                  item={item}
                  cart={cart}
                  product={products?.find(
                    (p) => p.id === item.variant.product_id
                  )}
                  handleUpdateItem={handleUpdateItem}
                />
              ))}
            </Flex>
            {cart?.items?.length > 2 && <Box sx={{ mt: "auto" }} />}
            <Flex
              sx={{
                mt: "-1px", // to hide border when divs are overlapping
                borderBottom: "1px solid #245437",
                borderTop:
                  cart?.items?.length > 2 ? "1px solid #245437" : "none",
                px: ["30px"],
                py: ["16px"],
                flexDirection: "column",
                zIndex: "9999",
              }}
            >
              <Flex sx={{ pb: "9px", flexDirection: "column" }}>
                {cart.discounts?.length ? (
                  <Flex
                    sx={{
                      pt: "15px",
                      pb: "15px",
                      justifyContent: "space-between",
                    }}
                  >
                    <Flex sx={{ alignItems: "center" }}>
                      <Text sx={{ fontSize: "14px", mr: "8px" }}>
                        Discount applied
                      </Text>
                      <Flex
                        sx={{
                          height: "12px",
                          cursor: "pointer",
                          mb: "4px",
                          svg: {
                            height: "12px !important",
                          },
                        }}
                      >
                        <CrossIcon
                          onClick={() => removeDiscount(cart.discounts[0].code)}
                        />
                      </Flex>
                    </Flex>
                    <Text sx={{ fontSize: "14px", fontWeight: "bold" }}>
                      - {formatPrices(cart, cart.discount_total)}
                    </Text>
                  </Flex>
                ) : (
                  <Flex sx={{}}>
                    <DiscountInput
                      sx={{ fontSize: ["12px", "14px"] }}
                      placeholder="Promo / Voucher"
                      onChange={(e) => setPromo(e.target.value)}
                      value={promo}
                    />
                    {promo && (
                      <ApplyPromoButton
                        sx={{
                          px: "15px",
                          fontSize: ["12px", "14px"],
                        }}
                        variant="primary"
                        onClick={() => addDiscount(promo)}
                      >
                        Apply
                      </ApplyPromoButton>
                    )}
                  </Flex>
                )}
                {cart.gift_cards?.length ? (
                  <Flex
                    sx={{
                      pt: "15px",
                      pb: "15px",
                      justifyContent: "space-between",
                    }}
                  >
                    <Flex sx={{ alignItems: "center" }}>
                      <Text sx={{ fontSize: "14px", mr: "8px" }}>
                        Gift Card applied
                      </Text>
                      <Flex
                        sx={{
                          height: "12px",
                          cursor: "pointer",
                          mb: "4px",
                          svg: {
                            height: "12px !important",
                          },
                        }}
                      >
                        <CrossIcon
                          className="discount-close-icon"
                          onClick={() => removeGiftCard()}
                        />
                      </Flex>
                    </Flex>
                    <Text sx={{ fontSize: "14px", fontWeight: "bold" }}>
                      -{" "}
                      {formatMoneyAmount({
                        currencyCode: cart.region.currency_code,
                        amount: cart.gift_card_total,
                      })}
                    </Text>
                  </Flex>
                ) : (
                  <Flex sx={{}}>
                    <DiscountInput
                      sx={{ fontSize: ["12px", "14px"] }}
                      placeholder="Gift Card"
                      onChange={(e) => setGiftCard(e.target.value)}
                      value={giftCard}
                    />
                    {giftCard && (
                      <ApplyPromoButton
                        sx={{
                          px: "15px",
                          fontSize: ["12px", "14px"],
                        }}
                        variant="primary"
                        onClick={() => addGiftCard(giftCard)}
                      >
                        Apply
                      </ApplyPromoButton>
                    )}
                  </Flex>
                )}
                <Flex
                  sx={{
                    borderTop: "2px dotted #245437",
                    pt: "15px",
                    justifyContent: "space-between",
                  }}
                >
                  <Text sx={{ fontSize: ["12px", "14px"] }}>Subtotal</Text>
                  <Text sx={{ fontSize: ["12px", "14px"], fontWeight: "bold" }}>
                    {formatPrices(cart, cart.subtotal)}
                  </Text>
                </Flex>
                {cart.shipping_methods?.length ||
                checkoutCart.shipping_methods?.length ? (
                  <Flex
                    sx={{
                      pt: "10px",
                      justifyContent: "space-between",
                    }}
                  >
                    <Text sx={{ fontSize: ["12px", "14px"] }}>Shipping</Text>
                    <Text
                      sx={{ fontSize: ["12px", "14px"], fontWeight: "bold" }}
                    >
                      {formatCartShippingTotal(
                        isCheckout ? checkoutCart : cart
                      )}
                    </Text>
                  </Flex>
                ) : null}
                <Flex
                  sx={{
                    pt: "8px",
                    justifyContent: "space-between",
                  }}
                >
                  <Text sx={{ fontSize: ["18px", "20px"] }}>Total</Text>
                  <Text sx={{ fontSize: ["18px", "20px"], fontWeight: "bold" }}>
                    {formatCartTotal(isCheckout ? checkoutCart : cart)}
                  </Text>
                </Flex>
              </Flex>
              {!isCheckout && (
                <CheckoutButton
                  variant="primary"
                  onClick={() => handleToCheckout()}
                  sx={{ px: "13px" }}
                >
                  <Text sx={{ fontSize: ["14px", "16px"] }}>
                    Proceed To Checkout
                  </Text>
                </CheckoutButton>
              )}
              {freeShippingLimit && freeShippingLimit > cart.subtotal && (
                <FreeShippingText
                  sx={{
                    fontSize: ["12px", "12px"],
                    mb: "15px",
                    fontWeight: "bold",
                  }}
                >
                  You are{" "}
                  {formatPrices(cart, freeShippingLimit - cart.subtotal)} away
                  from {/* <Text as="span" sx={{ ml: "8px" }}> */}
                  Free shipping
                  {/* </Text> */}
                </FreeShippingText>
              )}
            </Flex>
            {!isCheckout && <CartUpsell />}
          </Flex>
        )}
      </Wrapper>
    </CartWrapper>
  )
}

export default CartDrawer
